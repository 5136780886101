import { arrayBufferToBase64 } from "./utils";

class QueueItem {
  timestamp = Date.now();
  action;
  args;
  _id;

  constructor(body, action) {
    return new Promise(async (resolve, reject) => {
      try {
        this.action = action;
        this.body = body;
        this._id = arrayBufferToBase64(await window.crypto.subtle
          .digest("SHA-256", new TextEncoder().encode(JSON.stringify(body))))
        resolve(this)
      } catch (e) {
        reject(e.message)
      }

    })

  }
}

export default QueueItem;
