// ############################   FUNCTIONS TO HANDLE WEBSOCKET   ##########################################

import { arrayBufferToBase64 } from "./utils";

let events = require('events');

class ManagedWS {
  currentWebSocket;
  _id;
  events = new events.EventEmitter();
  queue = [];
  defaultOptions = {
    url: '',
    onOpen: null,
    onMessage: null,
    onClose: null,
    onError: null,
    timeout: 30000
  }


  constructor(options) {
    if (!options.url) {
      throw new Error('URL must be set')
    }
    this.defaultOptions = Object.assign(this.options, options);
    this.join()
  }

  get options() {
    return this.defaultOptions
  }

  join() {
    return new Promise((resolve, reject) => {
      try {

        this.currentWebSocket = new WebSocket(this.options.url);
        this.error();
        this.close();
        this.open();
        this.message();
        resolve();
      } catch (e) {
        console.error(e);
        reject(e)
      }
    })

  }

  send = (message) => {
    return new Promise(async (resolve, reject) => {
      if (this.currentWebSocket.readyState === 1) {
        const hash = await window.crypto.subtle
          .digest("SHA-256", new TextEncoder().encode(message));
        const ackPayload = {
          timestamp: Date.now(),
          type: 'ack',
          _id: arrayBufferToBase64(hash)
        }
        this.currentWebSocket.send(JSON.stringify(ackPayload))

        const ackResponse = () => {
          this.currentWebSocket.send(message)
          resolve()
          clearTimeout(timeout);
          this.events.removeListener('ws_ack_' + ackPayload._id, ackResponse)
        }

        this.events.on('ws_ack_' + ackPayload._id, ackResponse);
        let timeout = setTimeout(() => {
          console.error(`Websocket request timed out after ${this.options.timeout}ms`)
          this.events.removeListener('ws_ack_' + ackPayload._id, ackResponse)
          reject();
        }, this.options.timeout)


      } else {
        this.queue.push(message)
      }
    })

  }

  async error() {
    this.currentWebSocket.addEventListener("error", event => {
      console.log("WebSocket error, reconnecting:", event);
      if (typeof this.options.onError === 'function') {
        this.options.onError(event)
      }
    });
  }

  async close() {
    this.currentWebSocket.addEventListener("close", event => {
      console.info('Websocket closed', event)
      if (typeof this.options.onClose === 'function') {
        this.options.onClose(event)
      }

    });
  }

  async message() {
    this.currentWebSocket.addEventListener("message", async event => {
      let data = JSON.parse(event.data);

      if (data.ack) {
        this.events.emit('ws_ack_' + data._id);
        return;
      }
      if (data.nack) {
        console.log('Nack received')
        this.close()
        return;
      }
      if (typeof this.options.onMessage === 'function') {
        this.options.onMessage(event)
      }
    });
  }

  get readyState() {
    return this.currentWebSocket.readyState
  }

  async open() {
    this.currentWebSocket.addEventListener("open", async (event) => {
      if (this.queue.length > 0) {
        for (let i in this.queue) {
          this.send(JSON.stringify(this.queue[i]))
        }
      }
      this.queue = [];
      if (typeof this.options.onOpen === 'function') {
        this.options.onOpen(event)
      }
    });
  }

}

export default ManagedWS
