import config from "../config";
import ManagedWS from "./ManagedWS";

let events = require('events');

class ChatWS {
  constructor({ room, init }) {
    return new Promise(async (resolve, reject) => {
      try {
        const chatEvents = new events.EventEmitter();
        const options = {
          url: config.ROOM_SERVER_WS + room + "/websocket",
          onOpen: async (event) => {
            await socket.send(JSON.stringify(init))
            chatEvents.emit('open', event)
          },
          onMessage: event => {
            chatEvents.emit('message', event)
          },
          onClose: event => {
            chatEvents.emit('close', event)
          },
          onError: event => {
            chatEvents.emit('error', event)
          },
          timeout: 500
        }
        const socket = new ManagedWS(options);

        socket.events = chatEvents;
        socket._id = room;
        resolve(socket);
      } catch (e) {
      }

    })

  }
}

export default ChatWS;
